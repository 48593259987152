/* Portrait */
@media only screen and (max-width: 800px)  {
    body {
        overflow: hidden;
        width: 800px;
        height: 1334px;
    }
    .home-page-img .begin-btn .primary-button{
        width: 400px;
        height: 90px;
    }
    .begin-btn {
        margin-top: 20px;
    }
    .position-fixed img{
       width: 100vw;
        height: 1335px;
    }
    .page-container {
        padding: 90px;
    }
    .form-title {
        font-size: 33px;
        line-height: 33px;
    }
    .modal-content {
        padding: 60px;
        border-radius: 60px;
    }
    .terms-conditions .modal-footer {
        padding-top: 20px;
    }
    .modal-dialog {
        max-width: 80% !important;
    }
    .common-style{
        font-size: 30px;
    }
    .modal-header{
        font-size: 30px;
    }
    .terms-conditions .modal-header{
        padding-bottom: 20px;
        line-height: 35px;
    }
    .register-screen {
        padding-top: 50px;
    }
    .register-screen .gap-5 {
        gap: 2rem !important;
    }
    .secountry-btn, .primary-button{
        width: 510px;
        height: 80px;
    }
    .spin-later .secountry-btn {
        width: 450px !important;
    }
    .gamespin-screen .secountry-btn, .gamespin-screen .primary-button {
        width: 450px;

    }
    .modal-content ol li {
        font-size: 20px;
        line-height: 28px;
    }
    .register-screen .warning-content p{
        font-size: 18px;
        line-height: 24px;
    }
    .check-email {
        padding: 60px 0 15px 0;
        border-bottom: 2px solid #000;
    }
    input.form-control{
        font-size: 28px;
    }
    .gamespin-screen .modal-header {
        font-size: 38px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    
    }
    .register-screen .form-footer{
        padding-top: 260px;
    }
    .email-verfiy .verfiy-token input{
        width: 110px !important;
        margin-right: 50px;
        font-size: 35px;
        padding-bottom: 20px;
    }
    .spin-count {
        margin: 0px 20px;
        font-size: 50px;
    }
    .spin-text {
        font-size: 25px;
    }
    #myCanvas{
        width: 85%;
    }
    .primary-button, .secountry-btn{
        border-radius: 20px;
    }
    .gamespin-screen p {
        margin-bottom: 20px;
    }
    .gamespin-screen {
        padding-top: 0;
    }
    .terms-conditions .modal-body{
        height: 890px;
        padding: 40px 25px !important;
    }
    .gamespin-screen .arrow img {
        width: 120px;
        height: 120px
    }
    .pt-80 {
        padding-top: 40px;
    }
    .prize-modal .primary-button{
        margin: 30px 0;
    }
    .lottiefiles{
        margin-bottom: 30px;
    }
    .spin-modal .down-arrow img {
        width: 230px;
        height: 230px;
    }
    .modal-body {
        padding: 35px 0 !important;
    }
    .spin-later p {
        font-size: 23px;
        line-height: 26px;
    }
    .wheel-spiner #myCanvasContainer{
        padding-top: 20px;
    }
    .modal-dialog.no-win.spin-later .secountry-btn{
        width: 510px !important;
    }
    .spin-later .verifi-content p {
        padding-bottom: 20px;
        font-size: 30px;
        line-height: 40px;
        font-weight: 500;
    }
    // .verify-modal .modal-content{
    //     height: 700px;
    // }
    .email-verfiy .check-email p {
        font-size: 23px;
        line-height: 30px;
    }
    .prize-modal p{
        font-size: 28px;
        line-height: 36px;
    }
    .prize-modal .prize-visual{
        width: 100%;
        height: 370px;
        border-radius: 60px;
    }
    .prize-modal .modal-header {
        font-size: 45px;
    }
    .no-gap{
    gap: 0 !important
    }
    .no-gap .secountry-btn.common-style {
        margin-bottom: 20px;
    }
    .slider-wrapper{
        .slide {
            img{
                width: 800px;
                height: 1334px;
            }
            video{
                width: 800px;
                height: 1334px;
            }
        }
    }
}