.custom-slider {
    position: relative;
    height: 1920px;
    max-width: 1080px;
    margin: 0 auto;
    overflow: hidden;

    .slider-wrapper {
        display: flex;
    }

    .slide {
        position: relative;
        flex: 0 0 auto;
        width: 100%;
        opacity: 0;
        left: 0;
        top: 0;
        transition: opacity 0.5s ease-out;
        &.active {
            // position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
        }
    }
}