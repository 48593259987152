// Variables

$primaryFont: 'Montserrat', sans-serif;
$headingFont: 'Montserrat', sans-serif;

//Colors
$primary-color : #017C43;
$whiceColor: #ffffff;
$blackColor: #000000;
$btnColor: #fd5002;
$btnColortwo: #fa5100;
$btnBackground: linear-gradient(90deg, $btnColor 0%, $btnColortwo 100%);