.register-screen{
    padding-top: 200px;
    .form-footer{
        padding-top: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .warning-content{
      p{
          font-size: 24px;
          color: $blackColor;
          line-height: 28px;
          font-weight: 500;
      }
    }
}
.check-email{
    padding:  80px 0 20px 0;
    border-bottom: 3px solid #000;
}
.form-title{
  font-size: 40px;
  color: $blackColor;
  font-weight: $semibold;

}
input.form-control{
  font-size: 32px;
  font-weight: 400;
  border: 0;
  outline: none;
  &:focus{
    box-shadow: none;
  }
}
.form-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 40px;
    span{
      font-size: 18px;
      font-weight: 500;
    }
  }
  
  .form-group label:before {
    content: "";
    --webkit-appearance: none;
    background-color: transparent;
    border: 3px solid #AECAF3;
    padding: 18px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 8px;
  }
  
  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 20px;
    width: 14px;
    height: 29px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }


  // Email Verification
  .email-verfiy{
    .check-email{
      p{
        font-size: 32px;
        color: $blackColor;
        font-weight: 800;
        line-height: 38px;
      }
    }
    .verfiy-token{
      justify-content: space-between;
      input{
        @include box-size(150px !important, 100px);
        text-align: center;
        color: $blackColor;
        font-size: 40px;
        font-weight: 500;
        border: 0;
        outline: none;
        border-bottom: 3px solid $blackColor;
        margin-right: 80px;
        padding-bottom: 40px;
      }
      span{
        display: none;
      }
    }
    .resent-token {
      display: flex;
      flex-direction: column;
      grid-gap: 40px;
      padding-top: 40px;
      p{
        font-size: 24px;
        font-weight: 500;
        color: $blackColor;
        span{
          color: #183FEA;
        }
      }
  }
  .form-footer{
    padding-top: 160px;
  }
  .home-directed{
    padding-top: 140px;
    margin-top: 140px;
    p{
      font-size: 24px;
      font-weight: 500;
      color: $blackColor;
    }
  }
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}