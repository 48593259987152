// Box-width
@mixin box-size($width, $height) {
    width: $width;
    height: $height;
}

@mixin borderRadiusCard {  
    border-radius: 100px;  
} 

@mixin borderRadiusBtn {  
    border-radius: 32px;  
} 

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}