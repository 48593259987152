body{
    font-family: $primaryFont;
    overflow: hidden;
    width: 1080px;
    height: 1920px;
}

.page-container{
    padding: 120px;
    background: linear-gradient(180deg, rgba(212, 224, 242, 0.00) 55.73%, #D4E0F2 100%);
    height: 100vh;
    font-family: $primaryFont;
}
.home-img{
    margin: 0 auto;
    @include box-size(840px, auto);
    @include borderRadiusCard;
    overflow: hidden;
    img{
        width: 100%;
        object-fit: cover;
    }
}
.begin-btn{
    margin-top: 40px;
    @include flex-center;
}
.runing-text{
    font-size: 24px;
    line-height: 28px;
    color: $blackColor;
    font-weight: 500;

}
.spin-text{
    font-size: 32px;
}
.text-bold{
    font-weight: 700;
}
.lottiefiles{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    margin-bottom: 80px;
}

//Thank you style
.compain-txt{
    font-size: 28px;
    color: #6F6F6F;
    padding-top: 30px;
    margin-top: 30px;
}
.more-grp{
    margin-top: 60px;
    padding-top: 60px;
    h2{
        color: $blackColor;
        font-weight: 400;
        font-family: $headingFont;
        font-size: 60px;
    }
}
.social-link{
    padding-top: 40px;
    ul{
        li{
            a{
                display: flex;
                align-items: center;
                grid-gap: 16px;
                font-family: $primaryFont;
                font-size: 18px;
                color: $blackColor;
                font-weight: 700;
                text-decoration: none;
            }
        }
    }
}
#webpack-dev-server-client-overlay{
    display: none !important;
}
.count-text{
    font-size: 24px !important;
    color: #000;
    text-align: center;
    width: 100% !important;
}
.invalid-feedback{
    font-size: 18px;
    margin-top: 10px;
}
.home-page-img{
    .begin-btn{
        position: absolute;
        top: 30%;
        left: 25%; 
        .primary-button{
            background: #fa5100;
            height: 115px;
        }
    }
}
.lang-changes{
    font-size: 30px;
}
.gentral-text{
    font-size: 20px;
    line-height: 28px;
}
//loader 
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
  }
  
//   .loader {
//     width: 50px;
//     height: 50px; 
//   }