
.common-style{
    font-size: 48px;
    font-weight: $medium;
    font-family: $primaryFont;

}
.primary-button{
    width: 554px;
    height: 100px;
    border: 0;
    @include borderRadiusBtn;
    background: $btnBackground;
    &.common-style{
        color: $whiceColor;
    }
}
.secountry-btn{
    width: 554px;
    height: 100px;
    border: 5px solid $blackColor;
    @include borderRadiusBtn;
    background: transparent;
    &.common-style{
        color: $blackColor;
    }
}
button:disabled,button[disabled]{
    background: #cccccc;
    cursor: not-allowed !important;
  }
  .spin-later{
    .secountry-btn{
        width: 554px !important;
        padding: 20px 80px !important;
    }
    p{
        font-size: 24px;
        line-height: 28px;
    }
  }
  .qr-invalid{
    .primary-button{
        margin-bottom: 15px !important;
    }
    .secountry-btn.common-style{
        width: 100%;
        margin-bottom: 20px;
    }
  }